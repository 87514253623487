#game .inner{
    width:600px;
    max-width: 100%;
    margin: auto;
    height:100%;
}

#game .inner .m-title{
    color: #dc8d0f;
    text-align: center;
    font-weight: bold;
    text-align: center;
}
.contact-container{
    background: #fff;
    padding: 10px;
    margin-bottom: 10px;
}

.contact{
    display: flex;
    height: 40px;
    align-items: center;
}

.contact-title h4{
    color: #dc8d0f;
    font-weight: bold;
    font-size: 15px;
    float: left;
    margin-bottom: 0px !important;
}

.info-icon{
    position: absolute;
    right: 14px;
    font-size: 20px;
    color: #dc8d0f;
    font-weight: bold;
    z-index: 0;
}

.info-label{
    color: #000;
    font-weight: bold;
    margin-bottom: 2px;
    font-size: 15px;
}

.ifo-itms{
    padding-top: 20px;
    width: 90%;
    margin: auto;
}



.caption-text{
    color: #dc8d0f;
    text-align: center;
    font-weight: bold;
    background-color: #fff;
    padding: 10px 5px;
    
}

.plays-text{
    color: #dc8d0f;
    margin-top: 20px;
    text-align: center;
    font-weight: bold;
}

.spin-button{
    display: block;
    margin: auto;
    border: none;
    color: #fff;
    background: #dc8d0f;
    border-radius: 50vw;
    width: 100px;
    font-weight: bold;
    padding: 10px;
    font-size: 20px;
    margin-bottom: 20px;
}

.terms{
    color: #dc8d0f;
    margin: 20px 10px;
    text-align: center;
    font-size: 10px;
}

.win-modal{

}

.win-modal-top h5{
    font-size: 30px;
    font-weight: bold;
    text-align: center;
    display: block;
    color: #dc8d0f;
}

.win-modal .details-blk img{
    width: 95%;
    margin: auto;
    display: block;

    border-radius: 20px;
}

.win-modal .details-blk h6{
    text-align: center;
    font-size: 25px;
    color: #dc8d0f;
    font-weight: bold;
    text-transform: uppercase;
    margin: 20px auto;
}

.win-modal .details-blk p{
    color:#dc8d0f;
    text-align: center;
    font-size: 20px;
}